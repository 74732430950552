<template>
  <div class="my-10">
    <div class="flex justify-end" v-if="type === 'all'">
      <div
        class="flex gap-3 font-bold p-3 cursor-pointer"
        @click="exportData()"
      >
        Export
        <download class="h-5" />
      </div>
    </div>
    <div class="w-full mt-5">
      <div class="flex gap-3 px-5">
        <div class="w-1/12"></div>
        <div class="w-2/12">Date</div>
        <div class="w-2/12">Products</div>
        <div class="w-1/12">Unit Price</div>
        <div class="w-3/12">Location</div>
        <div class="w-1/12">Issued By</div>
        <div class="w-1/12"></div>
      </div>
      <div
        class="flex items-center gap-3 bg-white py-3 px-5 rounded-md my-4 hover:shadow-md"
        v-for="item in items"
        :key="item.id"
      >
        <div class="w-1/12">
          <input type="checkbox" v-model="item.selected" />
        </div>
        <div class="w-2/12 flex gap-2">
          <calendar-icon class="opacity-30" />
          {{ parseDate(item.created_at) }}
        </div>
        <div class="w-2/12">{{ item.service_name }}</div>
        <div class="w-1/12">{{ parseCash(item.service_amount) }}</div>
        <div
          class="w-3/12 flex items-center justify-center gap-2 bg-primary bg-opacity-10 border-primary border text-primary font-bold py-2 px-5 rounded-3xl"
        >
          <location-icon />
          {{ item.branch.branch_address }}
        </div>
        <div class="w-1/12">
          {{ item.staff_data.firstname }}
          {{ item.staff_data.lastname }}
        </div>
        <div class="w-1/12 flex gap-3">
          <div
            class="p-4 bg-blue-500 bg-opacity-20 rounded-full cursor-pointer"
            @click="$emit('edit', item)"
          >
            <edit-icon />
          </div>
          <div
            class="p-4 bg-red-500 bg-opacity-20 rounded-full cursor-pointer"
            @click="$emit('delete', item.id)"
          >
            <delete-icon />
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end gap-3" v-if="type === 'all'">
      <template v-for="page in Math.ceil(total / perPage)">
        <div
          class="bg-white py-2 px-4 cursor-pointer rounded-md"
          :key="page"
          :class="
            page === currentPage
              ? 'bg-primary cursor-default text-white shadow-md'
              : ''
          "
          @click="$emit('fetchPage', page)"
        >
          {{ page }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { parseMoney } from "@/utils/helpers";

export default {
  name: "ProductTable",
  components: {
    download: () => import("@/assets/icons/DownloadIcon.vue"),
    CalendarIcon: () => import("@/assets/icons/CalendarIcon"),
    LocationIcon: () => import("@/assets/icons/LocationIcon.vue"),
    EditIcon: () => import("@/assets/icons/EditIcon.vue"),
    DeleteIcon: () => import("@/assets/icons/DeleteIcon.vue"),
  },
  props: {
    allItems: {
      required: true,
      type: Array,
    },
    perPage: {
      required: true,
      type: Number,
    },
    total: {
      required: true,
      type: Number,
    },
    currentPage: {
      required: true,
      type: Number,
    },
    type: {
      required: false,
      type: String,
      default: "all",
    },
  },
  methods: {
    exportData() {
      const objArray = this.exportedData;
      // https://stackoverflow.com/a/64663550/5454933
      var arr = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
      var str =
        `${Object.keys(arr[0])
          .map((value) => `"${value}"`)
          .join(",")}` + "\r\n";
      var csvContent = arr.reduce((st, next) => {
        st +=
          `${Object.values(next)
            .map((value) => `"${value}"`)
            .join(",")}` + "\r\n";
        return st;
      }, str);
      var element = document.createElement("a");
      element.href = "data:text/csv;charset=utf-8," + encodeURI(csvContent);
      element.target = "_blank";
      element.download = "export.csv";
      element.click();
    },
    parseDate(date) {
      return moment(date).format("D MMM, YYYY");
    },
    parseCash(value) {
      return parseMoney(value);
    },
  },
  computed: {
    items() {
      return this.allItems;
    },
    exportedData() {
      let dat = [];

      this.items.forEach((item) => {
        if (item.selected) {
          dat.push(item);
        }
      });

      return dat;
    },
  },
};
</script>
